.animation {
    &.is-slide-up {
        position: relative;
        transform: translateY(50%);
        &.slide-up {
            transition: transform 0.25s ease-in-out;
            transform: translateY(0);
        }
    }
    &.is-fade-in {
        opacity: 0;
        &.fade-in {
            transition: opacity 0.25s ease-in-out;
            opacity: 1;
        }
    }
    &.is-fade-slide-in {
        position: relative;
        transform: translateY(50%);
        opacity: 0;
        &.fade-slide-in {
            transition: transform 0.25s ease-in-out;
            transform: translateY(0);
            transition: opacity 0.25s ease-in-out;
            opacity: 1;
        }
    }
}

.js-parallax {
    &.is-parallax-1 {
      @include from ($tablet) {
        background: url("../../../images/background-1.jpg") center center / auto repeat;
      }
      background: url("../../../images/background-1-mobile.jpg") center center / auto repeat;
      @media screen and (orientation: landscape) {
        background-size: 100vw; 
      }
    }
    &.is-parallax-2 {
      @include from ($tablet) {
        background: url("../../../images/background-2.jpg") center center / auto repeat;
      }
      background: url("../../../images/background-2-mobile.jpg") center center / auto repeat;
        @media screen and (orientation: landscape) {
          background-size: 100vw; 
        }
    }
}
