.text-link {
    display: inline-block;
    font-family: $font-family-links;
    font-size: 1.125rem;
    text-decoration: none;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    position: relative;
    padding-right: calc(35px + 0.5rem + 0.5rem);

    &::after {
        content: "";
        position: absolute;
        top: 4px;
        right: 0.5rem;
        background: url("../images/arrow-right-bright-green.svg") center center
            no-repeat;
        display: inline-block;
        width: 35px;
        height: 15px;
        transition: opacity 0.25s ease-out;
    }

    &:hover {
        color: $button-color-bright;
        text-decoration: underline;
    }

    &.has-back-arrow {
        padding-right: 0.5rem;
        padding-left: calc(35px + 0.5rem);

        &::after {
            content: none;
            width: unset;
            height: unset;
            top: unset;
            right: unset;
        }

        &::before {
            content: "";
            position: absolute;
            top: 4px;
            left: 0;
            background: url("../images/arrow-right-bright-green.svg") center center
            no-repeat;
            transform: rotate(180deg);
            display: inline-block;
            width: 35px;
            height: 15px;
            transition: opacity 0.25s ease-out;
        }
    }

    @include mobile {
        font-size: 1rem;
    }
}
