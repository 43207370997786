.two-color-section {
  background-color: $color-background-light-gray;

  .column2 {
    background-color: $color-background-blue;
  }

  @include until($tablet) {
    .column1 {
      padding: 3rem 1.5rem;
    }

    .column2 {
      padding: unset;
      padding: 3rem 0;
    }
  }

  @include from($tablet) {
    padding: $section-padding;
    position: relative;

    &.is-large {
      padding: $section-padding-large;
    }

    &.is-medium {
      padding: $section-padding-medium;
    }

    .background2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 50vw;
      height: 100%;
      background-color: $color-background-blue;
    }

    .column1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
