.press-material {
    .profile-information {
        display: flex;

        .profile-image {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            img {
              border-radius: 50%;
            }
        }

        .profile-details {
            padding-left: 2rem;
        }
    }
}

.assets-brand,
.assets-nfx {
    position: relative;
    padding-left: calc(1rem + 28px);

    &::before {
        content: url('../../images/icon-brand-assets.svg');
        position: absolute;
        left: 0;
    }
}

.assets-nfx::before {
    content: url('../../images/icon-file-download.svg');
}