$sidebar-hd-spacing: 5rem;

.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100dvh;
    background-color: white;
    z-index: 2147483641; //one index above cosentlayer button
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow: auto;

    padding: 0 1rem;

    @include from($fullhd) {
        padding-right: $sidebar-hd-spacing;
        padding-left: $sidebar-hd-spacing;
    }

    &.is-active {
        transform: translateX(0);
    }

    .menu-list:not(.is-editmode) {
        .navbar-item {
            color: white;

            &:hover,
            &:focus {
                color: $color-text;
            }
        }
    }

    &[open] {
        transform: translate(0);
        transition: transform 1s ease-in-out;
    }

    &:focus-visible {
        outline: none;
    }

    .navbar {
        .navbar-menu {
            display: block;
            box-shadow: none;

            > .navbar-item {
                @include font-scale(28px, 80px);
            }
        }

        .navbar-dropdown {
            .navbar-item {
                @include font-scale(24px, 24px);

                font-weight: $font-weight-regular;
            }
        }

        .navbar-item {
            padding-left: 0;
            padding-right: 0;
        }

        @include from($desktop) {
            align-items: center;

            .navbar-menu {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
                padding-right: 20%;

                .navbar-item {
                    padding: 0;
                }

                > .navbar-item {
                    font-weight: $weight-bold;

                    &:not(:last-of-type) {
                        margin-right: 3rem;
                    }

                    &.has-dropdown {
                        margin-right: 5rem;
                        padding-right: 1rem;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .navbar-item {
        transform: scale(1, 0);
        transform-origin: bottom;
        transition: transform 200ms ease-in-out;
        transition-delay: 300ms;
    }

    &.is-active .navbar-item {
        transform: scale(1, 1);
    }

    @include until($desktop) {
        .navbar-item,
        .navbar-link {
            font-weight: $font-weight-bold;

            &.has-dropdown {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.sidebar-logo {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    height: $navbar-height;

    img {
        width: 130px;

        @include from($desktop) {
            width: 200px;
        }

        @include from($fullhd) {
            left: $sidebar-hd-spacing;
        }
    }
}

.sidebar-footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;

    &.is-active {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        visibility: visible;
    }
}
