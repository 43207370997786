//global
.pl-2-5 {
  padding-left: 2.5rem;
}

.content {

  h1,
  h2,
  h3,
  h4,
  h5 {
    @include font-scale(18px, 24px);
    line-height: 1.3;
  }
}


@include from($tablet) {
  .p-4-mobile {
    padding: 2rem;
  }

  .has-border-desktop {
    border: 2px solid $color-background-light-gray;
    border-radius: 5px;
  }
}

@include until($tablet) {
  .is-flex-direction-column-reverse-mobile {
    flex-direction: column-reverse;
  }
  .is-gapless-column-mobile {
    padding-top: 0;
    padding-bottom: 0;
  }
}

//button
.buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.button {
  color: $color-text-light;
  background-color: $button-color-blue;
  white-space: break-spaces;

  &::before {
    background: url(../images/arrow-right-blue.svg) center center no-repeat;
  }

  &::after {
    background: url(../images/arrow-right-white.svg) center center no-repeat;
  }

  &:hover {
    color: $color-text;
    border: 2px solid $button-color-blue;
  }

  &.is-green {
    background-color: $object-color-secondary;

    &:before {
      background: url(../images/arrow-right-bright-green.svg) center center no-repeat;
    }

    &:hover {
      background-color: transparent;
      color: $color-text;
      border: 2px solid $button-color-bright;
    }
  }



  &.is-magenta {
    background-color: $object-color-magenta;

    &:before {
      background: url(../images/arrow-right-magenta.svg) center center no-repeat;
    }

    &:hover {
      background-color: transparent;
      color: $color-text;
      border: 2px solid $button-color-magenta;
    }
  }



  &.is-dark {
    background-color: $color-background-black;
    color: $color-text-light;

    &:before {
      background: url(../images/arrow-right-white.svg) center center no-repeat;
    }

    &:hover {
      background-color: transparent;
      color: $color-text;
      border: 2px solid $button-color-white;

    }
  }

  &.keep-text-color:hover {
    color: $color-text-light;
  }


  &.is-fullwidth {
    width: 100%;
  }

}

//text-link
.text-link {
  &:hover {
    color: $color-text-link;
  }

  &::after {
    background: url("../images/arrow-right-blue.svg") center center no-repeat;
  }

  &.is-partner::after {
    background: url("../images/arrow-right-magenta.svg") center center no-repeat;
  }

  &.is-advertiser::after {
    background: url("../images/arrow-right-bright-green.svg") center center no-repeat;
  }
}

//anker-link
.anker-link {
  background-color: $color-background-white;

  &:hover {
    background-color: $color-background-white;
  }

  &.is-active {
    background-color: $color-background-blue;
  }
}

//tag
.tag {
  background-color: $color-background-blue;
  color: $color-text-light;
}



//typo
a {
  text-decoration-color: $color-text-link;

  &.is-publisher {
    text-decoration-color: $color-is-publisher;

    &:hover {
      color: $color-is-publisher
    }
  }

  &.is-advertiser {
    text-decoration-color: $color-is-advertiser;

    &:hover {
      color: $color-is-advertiser;
    }
  }
}

.is-color-magenta {
  color: $color-text-magenta;
}

.is-color-fq {
  color: $object-color-primary;
}

.is-color-secondary {
  color: $color-text-secondary;
}

.is-background-magenta {
  background-color: $color-background-magenta;
}

.is-background-black {
  background-color: $color-background-black;
}

.is-background-dark {
  background-color: $color-background-dark;
}

//show-more
.show-more {

  &::before {
    padding-right: 0.3rem;
    content: url('../../../images/icon-show-more-blue.svg');

    &.is-active::before {
      content: url('../../../images/icon-show-more-blue.svg');
      ;
    }

  }

  &.is-publisher::before {
    padding-right: 0.3rem;
    content: url('../../../images/icon-show-more-green.svg');

    &.is-active::before {
      content: url('../../../images/icon-show-more-green.svg');
      ;
    }
  }

  &.is-advertiser::before {
    padding-right: 0.3rem;
    content: url('../../../images/icon-show-more-magenta.svg');

    &.is-active::before {
      content: url('../../../images/icon-show-more-magenta.svg');
      ;
    }

  }

}


//list-item
.list-items ul li {
  &::before {
    background: url('../../images/check-blue.svg') center center/contain no-repeat;
  }

  &.is-advertiser::before {
    background: url('../../images/check-magenta.svg') center center/contain no-repeat;
  }

  &.is-publisher::before {
    background: url('../../images/check-green.svg') center center/contain no-repeat;
  }
}


//box & quote-slider & slider
.box {
  width: 100%;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @include from($tablet) {
    &.has-padding-small {
      padding: 0.75rem;
    }
  }

  &.is-feature {
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .box-content {
      padding: 2rem;
    }

    .cta {
      margin-top: auto;
      margin-bottom: 2rem;
    }
  }

  .box-badge {
    background: url('../../images/icon-badge-blue.svg') center center no-repeat;
    height: 80px;
    position: absolute;
    top: -0.2rem;
    left: 3rem;
    padding: 0.4rem;
  }

  &.has-no-shadow {
    box-shadow: none;
  }

  .top-banner {
    padding: 1rem 0.5rem;
    border-radius: $border-radius $border-radius 0 0;

    &.is-dark {
      background-color: $color-background-black;
    }
  }
}

.quote-slider,
.kpi-box {
  .quote-slider-container {
    border-radius: $border-radius;

    .box-container {
      background-color: $color-background-white;
      border-radius: $border-radius;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
}

.kpi-box .kpi-container {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.quote-slider .quote-slider-container {
  background-color: $color-background-light-gray;

  .slider-container .swiper {
    .swiper-pagination-bullet-active {
      background-color: $object-color-primary;
    }

    .swiper-wrapper {
      color: unset;

    }
  }
}

.slider.has-navigation,
.slider.has-headline {
  .swiper-pagination-bullet-active {
    background-color: $object-color-primary;
  }

  .swiper-button-next:hover::after {
    content: url('../../images/icon-navigation-right-blue.svg')
  }

  .swiper-button-prev:hover::after {
    content: url('../../images/icon-navigation-left-blue.svg')
  }
}

//customer-quote slider

.customer-quote-slider {
  .swiper-pagination {
    display: flex;
    justify-content: flex-end;

    &.is-green-white,
    &.is-green {
      .swiper-pagination-bullet {
        background-color: $color-background-white;
      }

      .swiper-pagination-bullet-active {
        background-color: $object-color-secondary;
      }
    }

    &.is-magenta-white,
    &.is-magenta {
      .swiper-pagination-bullet {
        background-color: $color-background-white;
      }

      .swiper-pagination-bullet-active {
        background-color: $color-background-magenta;
      }
    }
  }

}

.marquee {
  @include until($tablet) {
    margin: 0 -1.5rem;
  }

  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
}

//card
.card {
  border-radius: $border-radius;
}

.card.is-news {
  border-radius: unset;

  img {
    max-width: 240px;
    border-radius: 0;
  }

  figure {
    @include from($tablet) {
      aspect-ratio: 2/1;
    }
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//hero
.hero.is-large {
  max-height: unset;
}

//navbar
.navbar-container {
  pointer-events: none;
}

a.navbar-item:hover,
a.navbar-item:focus-within,
a.navbar-item:focus {
  color: $color-text-link;
  text-decoration-color: $color-text-link;
}
.navbar {
  .text-color-primary {
    color: $color-text-blue;
  }
  .underlined-blue {
    text-decoration: underline;
    text-decoration-color: $color-text-blue; 
    /* text-underline-offset: 5px; */
  }
}

//quote
.quote {
  &.is-magenta-white {
    svg:nth-of-type(1) {
      path {
        fill: $color-is-advertiser;
      }
    }

    span:nth-of-type(1) {
      color: $color-is-advertiser;
    }
  }
}

//twoColorSection
.two-color-section {
  .background2 {
    background-color: transparent;
  }

  .column2 {
    background-color: transparent;
  }
}

//tags
.tag-row {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 0.5rem;

}

//advertiser teaser
.header-logo-container {
  display: flex;
  padding: 1rem;
  max-width: 100%;
  aspect-ratio: 1/1;
  background-color: $color-background-white;
  border-radius: $border-radius;
  align-items: center;
  justify-content: center;
}

.advertiser-teaser-description,
.campaign-teaser-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.safari {
  .advertiser-teaser,
  .campaign-teaser {
    &:not(.is-active) {
      .advertiser-teaser-description,
      .campaign-teaser-description {
        p, h1, h2, h3, h4, h5, ul {
          display: inline;
        }
      }
    }
  }
} 

.advertiser-teaser,
.campaign-teaser {
  gap: 1rem;
  min-height: 250px;
  &.is-active {

    .advertiser-teaser-description,
    .campaign-teaser-description {
      -webkit-line-clamp: unset;
    }
  }
}



.advertiser-teaser {
  .img-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  figure {
    max-width: 240px;
    justify-content: center;
    align-items: center;
  }
}

.commission-container {
  justify-content: center;

  @include from($tablet) {
    flex-direction: column;
  }

  div {
    padding: 1.5rem;
    border-bottom: 1px solid $color-background-white;

    @include until($tablet) {
      border-bottom: none;

      &:not(:last-child) {
        border-right: 1px solid $color-background-white;
      }
    }
  }

}

.campaign-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 0;

  tr:nth-of-type(2) {
    td {
      padding-top: 0.5rem;
    }
  }

  tr:last-of-type {
    td {
      padding-bottom: 0.5rem;
    }
  }

  td {
    padding: 0.1rem 0;
  }
}

td.commission-table-column {
  text-align: center;
  min-width: 60px;
  margin-left: 1rem;
  background-color: $color-background-light-gray;

  @include until($tablet) {
    min-width: 50px;
  }
}

.advertiser-teaser-overview>div:not(:last-child) {
  margin-bottom: 1.5rem;
}

//background
.js-parallax {
  &.is-advertiser {
    @include from ($tablet) {
      background: url("../../../images/parallax-advertiser-1.webp") center center / auto repeat;
    }

    background: url("../../../images/parallax-advertiser-1-mobile.webp") center center / auto repeat;

    @media screen and (orientation: landscape) {
      background-size: 100vw;
    }
  }
  &.is-advertiser-2 {
    @include from ($tablet) {
      background: url("../../../images/parallax-advertiser-2.webp") center center / auto repeat;
    }

    background: url("../../../images/parallax-advertiser-2-mobile.webp") center center / auto repeat;

    @media screen and (orientation: landscape) {
      background-size: 100vw;
    }
  }

  &.is-partner {
    @include from ($tablet) {
      background: url("../../../images/background-partner-grainy.webp") center center / auto repeat;
    }

    background: url("../../../images/background-partner-grainy-mobile.webp") center center / auto repeat;

    @media screen and (orientation: landscape) {
      background-size: 100vw;
    }
  }

  &.is-fq {
    @include from ($tablet) {
      background: url("../../../images/parallax-fq.webp") center center / auto repeat;
    }

    background: url("../../../images/parallax-fq-mobile.webp") center center / auto repeat;

    @media screen and (orientation: landscape) {
      background-size: 100vw;
    }
  }
}

.modal .modal-content {
  padding: 3rem;
  border-radius: $border-radius;
  @include until($tablet) {
    width: auto;
  }
}

.modal {
  z-index: 9999999999;
}