.footer {
    .footer-menu {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include from($tablet) {
            justify-content: flex-end;
            flex-direction: row;
        }
        a.navbar-item {
            text-decoration: none;
            &:hover {
              color: $color-text-link;
              transition: color 0.25s ease-out;
              text-decoration: underline;
              background-color: $color-background-light-gray;
            }
        }

        @include until($desktop) {
            .navbar-menu {
                display: block;
                box-shadow: none;

                .navbar-item {
                    padding-left: 0;
                }
            }
        }
    }
    .icons {
        display: flex;
        justify-content: center;
        gap: 1rem;
        justify-content: center;
        @include from($tablet) {
            justify-content: flex-end;
        }
    }
}

.legal-footer {
    color: $color-text-light;

    display: flex;
    flex-direction: column;
    text-align: center;
    @include from($tablet) {
        flex-direction: row;
        justify-content: space-between;
    }
}
