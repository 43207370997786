$bar-radius: 25vh;
$bar-trigger-Y: 100vh;

@include from($desktop) {
    $bar-trigger-Y: 150vh;
}

$bars: (
    (color: #FF4B41, duration: 500ms, transform: translateX(calc(-100% - $bar-radius))),
    (color: #E6E623, duration: 500ms, transform: translateX(calc(-200% - $bar-radius))),
    (color: #0091FF, duration: 500ms, transform: translateX(calc(100% - $bar-radius))),
    (color: #46DCA5, duration: 500ms, transform: translateX(calc(200% - $bar-radius))),
);

.scrollytelling-bar-container {
    position: relative;
    height: 300vh;
    pointer-events: none;
    transform: translateY(-$bar-trigger-Y);
    margin-bottom: -$bar-trigger-Y;

    > * {
        height: 100vh;
    }

    .scrollytelling-bars {
        position: sticky;
        overflow-x: hidden;
        top: $bar-trigger-Y;
        width: 100%;
        pointer-events: none;

        > .bar {
            position: absolute;
            top: 0;
            height: 25vh;
            width: calc(100% + $bar-radius*2);
            border-radius: $bar-radius;
            pointer-events: all;

            @each $bar in $bars {
                $i: index($bars, $bar);

                &:nth-child(#{$i}) {
                    top: calc(25vh * ($i - 1));
                    background-color: map-get($bar, 'color');
                    transform: map-get($bar, 'transform');
                }
            }

            &:nth-child(1) {
                z-index: 1;
            }
        }
    }

    .scrollytelling-content {
        background-color: #FF4B41;
        position: sticky;
        top: $bar-trigger-Y;

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        transform: translateY(100vh);
        padding: $gap;
        overflow-x: hidden;
        z-index: 2;
        pointer-events: all;

        > .container {
            flex-grow: 0;
        }
    }

    &.is-editmode {
        height: auto;
        transform: none;
        margin-bottom: 0;

        .scrollytelling-bars,
        .scrollytelling-content {
            transform: none;
            top: 0;
            margin-bottom: 0;
        }

        .scrollytelling-content {
            height: auto;
        }

        .scrollytelling-bars .bar {
            transform: translateX(-$bar-radius);
        }

        .scrollytelling-content {
            transform: translateY(0);
        }
    }
}