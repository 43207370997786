.button {
    display: inline-block;
    font-family: $font-family-links;
    color: $color-text;
    position: relative;
    font-family: $font-family-bold;
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    white-space: nowrap;
    padding: $button-medium-y $button-medium-x;
    padding-right: calc($button-medium-x + 34px + 0.5rem);
    border: 2px solid transparent;
    text-decoration: none;
    background-color: $button-color-bright;
    transition: background-color 0.25s ease-out, color 0.25s ease-out,
        border 0.25s ease-out;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: calc($button-medium-y + 3px);
        right: $button-medium-x;
        background: url("../images/arrow-right-bright-green.svg") center center
            no-repeat;
        display: inline-block;
        width: 34px;
        height: 15px;
        transition: opacity 0.25s ease-out;
    }
    &::after {
        background: url("../images/arrow-right-dark-green.svg") center center
            no-repeat;
    }
    &:hover {
        transition: background-color 0.25s ease-out, color 0.25s ease-out,
            border 0.25s ease-out;
        background-color: transparent;
        border: 2px solid $button-color-bright;
        color: $button-color-bright;
        cursor: pointer;
        &::after {
            transition: opacity 0.25s ease-out;
            opacity: 0;
        }
    }
    &:focus {
        text-decoration: underline;
    }
    &.is-dark-outlined {
        background-color: transparent;
        border: 2px solid $button-color-primary;
        &::before {
            background: url("../images/arrow-right-white.svg") center center
                no-repeat;
        }
        &::after {
            background: url("../images/arrow-right-dark-green.svg") center
                center no-repeat;
        }
        &:hover {
            color: $button-color-white;
            background-color: $button-color-primary;
            border: 2px solid transparent;
            &::after {
                transition: opacity 0.25s ease-out;
                opacity: 0;
            }
        }
    }
    &.is-white-outlined {
        background-color: transparent;
        border: 2px solid $button-color-white;
        color: $button-color-white;
        &::before {
            background: url("../images/arrow-right-dark-green.svg") center
                center no-repeat;
        }
        &::after {
            background: url("../images/arrow-right-white.svg") center center
                no-repeat;
        }
        &:hover {
            color: $button-color-primary;
            background-color: $button-color-white;
            border: 2px solid transparent;
            &::after {
                transition: opacity 0.25s ease-out;
                opacity: 0;
            }
        }
    }
    &.is-arrowless {
        padding-right: $button-medium-x;
        @include mobile {
            display: flex;
            justify-content: center;
        }
        &::before,
        &::after {
            content: none;
        }
    }
    @include mobile {
        padding: $button-small-y $button-small-x;
        padding-right: calc($button-small-x + 35px + 0.5rem);
        font-size: 14px;
        &::before,
        &::after {
            top: calc($button-small-y + 3px);
            right: $button-small-x;
        }
        &.is-full-width {
            width: 100%;
        }
    }
}

.buttons {
    @include mobile {
        &.has-mobile-wrap {
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}
