//Section Background
.is-background-light-gray {
    background-color: $color-background-light-gray;
}
.is-background-primary {
    background-color: $color-background-primary;
}
.is-background-secondary {
    background-color: $color-background-secondary;

    a {
        &:hover {
            color: currentColor;
        }
    }
}
.is-background-blue {
    background-color: $color-background-blue;
}
.is-background-white {
    background-color: white;
}
.is-background-orange {
    background-color: $object-color-yellow;
}
.is-background-red {
    background-color: $object-color-red;
}

//Objects/Components
.is-object-color-primary {
    background-color: $object-color-primary;
}
.is-object-color-secondary {
    background-color: $object-color-secondary;
}
.is-object-color-yellow {
    background-color: $object-color-yellow;
}
.is-object-color-red {
    background-color: $object-color-red;
}
.is-object-color-blue {
    background-color: $object-color-blue;
}
.is-object-color-purple {
    background-color: $object-color-purple;
}
