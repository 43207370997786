.case-preview {
    .headline {
       text-align: left;
       width: 80%;
    }
    .box1 {
        display: none;
    }
    .is-column-1 {       
        flex-direction: column;
       
    }
    .is-column-2 {  
        flex-direction: column;
    }
    @include from($tablet) {
        .headline {
            text-align: right;
            padding-right: 1.5rem;
        }
        .is-column-1,
        .is-column-2 {
          display: flex;
        }
        .box1 {
            display: block;
        }
        .is-column-1 {
            align-items: flex-end;
        }
        .is-column-2 {
            align-items: flex-start;
        }
    }
}