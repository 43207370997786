@font-face {
    font-family: "carloschi";
    font-display: swap;
    src: url("../../../fonts/Carloschi_Webfont/Light/"); /* IE9 Compat Modes */
    src: url("../../../fonts/Carloschi_Webfont/Light/?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../../../fonts/Carloschi_Webfont/Light/Carloschi-Light.woff2")
            format("woff2"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Light/Carloschi-Light.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Light/Carloschi-Light.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../fonts/Carloschi_Webfont/Light/Carloschi-Light.svg#Carloschi-Light")
            format("svg"); /* Legacy iOS */
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "carloschi";
    font-display: swap;
    src: url("../../../fonts/Carloschi_Webfont/Regular/"); /* IE9 Compat Modes */
    src: url("../../../fonts/Carloschi_Webfont/Regular/?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../../../fonts/Carloschi_Webfont/Regular/Carloschi-Regular.woff2")
            format("woff2"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Regular/Carloschi-Regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Regular/Carloschi-Regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../fonts/Carloschi_Webfont/Regular/Carloschi-Regular.svg#Carloschi-Regular")
            format("svg"); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "carloschi";
    font-display: swap;
    src: url("../../../fonts/Carloschi_Webfont/SemiBold/"); /* IE9 Compat Modes */
    src: url("../../../fonts/Carloschi_Webfont/SemiBold/?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.woff2")
            format("woff2"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.svg#Carloschi-SemiBold")
            format("svg"); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "carloschi-book";
    font-display: swap;
    src: url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.eot"); /* IE9 Compat Modes */
    src: url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.woff2")
            format("woff2"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../fonts/Carloschi_Webfont/Book/Carloschi-Book.svg#Carloschi-Book")
            format("svg"); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "carloschi-book";
    font-display: swap;
    src: url("../../../fonts/Carloschi_Webfont/SemiBold/"); /* IE9 Compat Modes */
    src: url("../../../fonts/Carloschi_Webfont/SemiBold/?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.woff2")
            format("woff2"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.woff")
            format("woff"),
        /* Modern Browsers */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../../../fonts/Carloschi_Webfont/SemiBold/Carloschi-SemiBold.svg#Carloschi-SemiBold")
            format("svg"); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
}
