form {
    &.has-no-labels {
        label {
            &.label {
                display: none;
                visibility: hidden;
            }
        }
    }

    .required:after {
        content:" *";
    }

    .field {
        .control,
        .select {
            width: 100%;
        }

        .help {
            &.is-danger {
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                margin-top: 0.5625rem;
                padding: 13px 23px;
                font-size: 14px;
                color: $color-text;
                background: rgba(255, 190, 35, .15);
                border-radius: 4px;
                border: 0;

                &::before {
                    content: url('../../images/info-icon.svg');
                    width: 1.3125rem;
                    height: 1.4375rem;
                    margin-right: 1rem;
                }
            }
        }

        ::placeholder {
            @extend .has-dark-lighter-gray-text;
        }

        ::-ms-input-placeholder {
            color: $color-text-dark-gray;
        }

        input:not([type="checkbox"]):not([type="submit"]):not([type="radio"]),
        select,
        textarea {
            width: 100%;
            background: $color-background-light-gray;
            color: $color-text-dark-gray;
            box-shadow: none;
            border: none;

            &:active,
            &:hover {
                border-color: transparent;
                box-shadow: none;
            }

            &.is-danger {
                border: 1px solid $object-color-orange;

                &:focus {
                    box-shadow: none;
                }
            }

            &:not(.is-danger) {
                &:active,
                &:focus {
                    border: 1px solid $button-color-blue;
                }
            }
        }

        select {
            &:invalid {
                @extend .has-dark-lighter-gray-text;
            }
        }

        .checkbox {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
            position: relative;
            cursor: pointer;
            vertical-align: middle;
            line-height: 1.6;

            //input {
            //    &[type="checkbox"] {
            //        appearance: none;
            //        -webkit-appearance: none;
            //        background-color: $color-text-light;
            //        margin: 0;
            //
            //        font: inherit;
            //        color: currentColor;
            //        min-width: 1.5rem;
            //        height: 1.5rem;
            //        border: 2px solid currentColor;
            //        transform: translateY(0.375rem);
            //
            //        &::before {
            //            content: '✔';
            //            position: absolute;
            //            left: 3px;
            //            top: -3px;
            //            font-size: 1.03125rem;
            //            color: currentColor;
            //            transform: scale(0);
            //            transition: transform ease-in-out .1s;
            //        }
            //
            //        &:checked {
            //            &::before {
            //                transform: scale(1);
            //            }
            //        }
            //    }
            //}

            &.required {
                &::after {
                    content: "";
                }
            }
        }

        .textarea {
            &:not([rows]) {
                min-height: 15em;
            }
        }

        button {
            &[type="submit"]:disabled {
                padding-right: 1.5rem;

                &::before,
                &::after {
                    background: none !important;
                    content: none !important;
                }

                &:hover,
                &:focus {
                    background-color: $button-color-bright;
                    color: $color-text;
                }
            }
        }

        .is-medium {
            &.input,
            &.textarea,
            &.select select {
                font-size: 1rem;
                height: 3.125em;
                padding-left: 1.375em;
            }
        }

        .is-size-8 {
            & + span {
                font-size: 0.875rem !important;
            }
        }
    }
}