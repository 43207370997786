$navbar-margin-top: 1rem;
$navbar-hd-spacing: 5rem;

body {
    position: relative;
}

.navbar-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.navbar {
    &.navbar-top {
        position: sticky;
        top: $navbar-margin-top;
        margin-top: $navbar-margin-top;
        pointer-events: all;
        padding: 0 1rem;
        z-index: 2147483699; //to be viewed obove the consentlayer button & sidebar

        @include from(1440px) {
            padding: 0 calc($navbar-hd-spacing + 0.5rem) 0 $navbar-hd-spacing;
        }

        &.has-pointer-events-none {
            pointer-events: none;
        }
    }
}

.navbar-item {
    opacity: 1;
    transition: opacity 300ms ease-in-out;

    &:hover {
        text-decoration: underline;
        text-decoration-color: $button-color-bright;
    }

    &.is-ghosted {
        opacity: 0;
        pointer-events: none;
    }

    &.is-active.is-active {
        text-decoration: underline;
        text-decoration-color: $button-color-bright;
        background: transparent;
    }

    &.has-dropdown {
        position: relative;
        margin-right: 2rem;

        &::after {
            content: '';
            position: absolute;
            width: 3rem;
            height: 3rem;
            top: 50%;
            left: calc(100% - 0.5rem);
            transform: translateY(-50%);
            background: url('/images/icon-chevron-down-black.svg') center center / contain no-repeat;

            @include until($desktop) {
                width: 2rem;
                height: 2rem;
                top: 0.6rem;
                right: 0;
                left: auto;
                transform: rotate(180deg);
            }
        }
    }
}

.navbar-burger {
    background-color: white;
    border-radius: $radius;
    padding-right: 2rem;
    padding-left: 2rem;
    pointer-events: all;

    &:hover {
        background-color: white;
    }

    span {
        height: 2px;
    }
}

.navbar-menu {
    font-family: $font-family-heading;
    font-weight: $font-weight-regular;

    @include font-scale(16px, 18px);
    text-transform: uppercase;

    .navbar-item {
        color: $color-text;
    }

    a {
        text-decoration: none;
    }

    @include from($desktop) {
        .navbar-burger {
            display: block;
        }
    }
}

.navbar-end {
    position: sticky;
    top: 0;
    background-color: white;
    border-radius: $radius;
    padding-left: 1rem;

    @include from($desktop) {
        &.is-movable {
            transform-origin: right center;
            transform: translateX(6px);
            transition: transform 300ms ease-out;

            &.is-closed {
                transform: translateX(100%);
            }
        }
    }
}

.navbar-top {
    .navbar-menu {
        overflow-x: hidden;
        overflow-y: visible;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;

        .navbar-item {
            &.is-active {
                background-color: transparent;
                color: inherit;
                text-decoration: none;

                &:hover {
                    color: $button-color-bright;
                }
            }
        }
    }
}

.navbar-dropdown {
    box-shadow: none;
}
