.scrollytelling-cta-container {
    position: relative;

    .scrollytelling-cta-content {
        padding: 0;

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 50vh;
            transition: opacity 200ms ease-in-out;

            @include from($tablet) {
                min-height: 100vh;
            }
        }

        .custom-component {
            > .container {
                flex-grow: 0;
            }
        }
    }

    .scrollytelling-cta {
        position: sticky;
        top: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include until($tablet) {
        .svg-container {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;

            .scrollytelling-cta {
                height: 50vh;
                z-index: -1;
            }
        }

        .content-container {
            position: relative;
            padding-top: 50vh;
        }
    }

    #Stars path {
        transition: transform 300ms ease-in-out;

        &:nth-child(1) {
            transform: translate(-110px, 130px);
            opacity: 0;
        }

        &:nth-child(2) {
            transform: translate(-100px, 100px);
            opacity: 0;
        }

        &:nth-child(3) {
            transform: translate(-150px, 45px);
            opacity: 0;
        }
    }
}

.editmode .scrollytelling-cta-container {
    position: relative;
    height: auto;
}

#User_Blue,
#User_Pink,
#User_Purple {
    transition: transform 200ms;
}