// Colors/Objects

$object-color-red: #ff4b41;
$object-color-yellow: #e6e623;
$object-color-orange: #ffbe23;
$object-color-purple: #9664ff;

//// FQ Colors
$object-color-primary: #0091ff;
$object-color-secondary: #46DCA5;
$object-color-magenta: #FF32B4;
$object-color-blue: #0091ff;


// Colors/Background(Section)
$color-background-gray: #d4d7d6;
$color-background-light-gray: #f0f3f6;
$color-background-primary: #0091ff;
$color-background-secondary: #46dca5;

//// FQ
$color-background-blue: #0091ff;
$color-background-magenta: #FF32B4;
$color-background-white: #fff;
$color-background-black: #000000;
$color-background-dark: #002039;


// Colors/Text
$color-text: #000000;
$color-text-light: white;
$color-text-secondary: #46dca5;
$color-text-link: #0091ff;
$color-text-dark-gray: #000000;
$color-text-dark-lighter-gray: rgba(15, 35, 25, 0.45);
//// FQ
$color-text-magenta: #FF32B4;
$color-text-blue: #0091ff;
$color-text-secondary: #46dca5;
$color-is-publisher: #46dca5;
$color-is-advertiser: #FF32B4;


// Colors/Buttons & Links
$button-color-bright: #46dca5;
$button-color-blue: #0091ff;
$button-color-magenta: #FF32B4;
$button-color-primary: #183830;
$button-color-white: white;
$button-color-light-gray: #f0f3f6;
$button-color-yellow: #e6e623;

// Font/Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

//Font/Family
$font-family-primary: carloschi-book, sans-serif;
$font-family-bold: carloschi, sans-serif;
$font-family-heading: carloschi, sans-serif;
$font-family-links: carloschi, sans-serif;

//Text-Flow
$nfx-text-flow-default-size: 1rem;
$nfx-text-flow-sizes: (
    is-small: 0.5rem,
    is-relative: 1em,
    is-large: 2rem,
);

$nfx-text-flow-sizes-mobile: (
    is-small: 0.5rem,
    is-relative: 1em,
    is-large: 1.5rem
) !default;

//FQ-border-radius
$border-radius: 20px;

//Button/Size
$button-medium-y: 0.8rem;
$button-medium-x: 1.5rem;
$button-small-y: 0.5rem;
$button-small-x: 1.5rem;

//Anker-link/Size
$anker-medium-y: 0.3rem;
$anker-medium-x: 0.8rem;
$anker-small-y: 0.1rem;
$anker-small-x: 0.6rem;

//Box/padding
$box-padding: 2.5rem;
$box-padding-large: 6.25rem 3rem;

//Header/Gap
$header-gap: 0rem;

//Bar
$max-bar-height: 900px;

//Bulma/Section
$section-padding: 4rem 1.5rem; // mobile
$section-padding-desktop: 5rem 3rem; //small
$section-padding-medium: 6.25rem 3rem;
$section-padding-large: 7.5rem 3rem;
//custom/Section
$section-padding-jumbo: 9.375rem;
$section-padding-smaller: 2rem;
$section-padding-tiny: 1.5rem;


//Bulma/navbar
$navbar-height: 3.25rem;
$burger-line-width: 40px;
$navbar-burger-color: #183830;
$navbar-background-color: transparent;
$navbar-dropdown-background-color: white;
$navbar-item-hover-color: $button-color-bright;
$navbar-item-active-background-color: transparent;
$navbar-item-active-color: $button-color-bright;
$navbar-dropdown-item-hover-color: $button-color-bright;
$navbar-dropdown-item-active-color: $button-color-bright;
$navbar-dropdown-background-color: $button-color-white;
$navbar-dropdown-boxed-shadow: none;
$navbar-dropdown-border-top: none;
$navbar-breakpoint: 1130px; 

//Bulma Color of <strong> text
$text-strong: inherit;

//Bulma color of form elements
$input-arrow: $button-color-blue;

//Bulma hero custom sizes
$hero-body-padding-medium: 10rem 4.5rem;
$hero-body-padding-large: 8rem 6rem;

// sass-lint:disable function-name-format
$colors: ();
$shades: (); 