.is-horizontally-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pull-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include from($tablet) {
        &.is-column-1 {
            align-items: flex-end;
            @include until($tablet) {
                align-items: center;
            }
        }
        &.is-column-2 {
            align-items: flex-start;
            @include until($tablet) {
                align-items: center;
            }
        }
    }
}
