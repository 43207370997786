.slider {
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            text-align: center;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
                display: block;
                /* width: 100%; */
                height: 100%;
                object-fit: cover;
            }
        }
        .swiper-pagination-bullet-active {
            background: #46dca5;
        }
    }
}
