$swiper-padding-bottom: 4rem;
$swiper-padding-left: 228px;

.slider {

  &.is-big {
    height: 640px;
  }

  &.is-medium {
    height: 500px;
  }

  &.is-short {
    height: 350px;
  }

  /*  .swiper-slide {
    object-fit: cover;
    flex-direction: column; */
  .swiper .swiper-slide {
    display: block;

    img {
      /* width: 100%; */
      height: 100%; 
    }
  }

  &.has-navigation,
  &.has-headline {


    .swiper {
      padding-bottom: $swiper-padding-bottom;
    }



    .swiper-pagination {
      bottom: 22px;
      transform: translateY(50%);
      text-align: start;
    }

    .swiper-pagination-bullet {
      background: black;
      opacity: 0.4;

      &-active {
        opacity: unset;
        background-color: $object-color-secondary;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      bottom: 0;
      top: auto;

      &::after {
        width: 44px;
        height: 44px;
      }
    }

    .swiper-button-prev {
      right: 4rem;
      left: auto;

      &::after {
        content: url('../../images/icon-navigation-left.svg')
      }
      &:hover::after {
        content: url('../../images/icon-navigation-left-green.svg')
      }
    }

    .swiper-button-next {
      &::after {
        content: url('../../images/icon-navigation-right.svg');
      }
      &:hover::after {
        content: url('../../images/icon-navigation-right-green.svg')
      }
    }

  }

  &.is-white {
    .swiper-button-prev::after {
      content: url('../../images/icon-navigation-left-white.svg')
    }

    .swiper-button-next::after {
      content: url('../../images/icon-navigation-right-white.svg')
    }



    .swiper-pagination-bullet {
      background: white;

      &-active {
        opacity: unset;
        background-color: $object-color-secondary;
      }
    }

  }

  &.has-headline {
    padding-left: $swiper-padding-left;
    position: relative;
    margin-top: 7rem;

    .headline-box {
      position: absolute;
      left: $swiper-padding-left;
      top: 50%;
      transform: translate(-50%, calc(-50% - $swiper-padding-bottom / 2));
      padding: 3rem;
      width: clamp(250px, 50%, 300px);
      z-index: 11;
      &.editmode {
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.editmode {
    height: auto;
  }
}

@include until($tablet) {
  /* .slider {
    margin-top: 9rem !important;
  } */

  .slider.has-headline {
    padding-left: unset;

    .headline-box {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .slider {
      [class*="slider-related-articles"] {
        width: 100vw;
        margin-left: - calc(0.75rem * 2);

        & .swiper-pagination {
            margin-left: calc(0.75rem * 2);
        }

        & .swiper-pagination ~ .swiper-button-next,
        & .swiper-pagination ~ .swiper-button-prev {
          display: none;
        }
      }
  }
}

.swiper {
  display: grid;
}

.swiper-wrapper {
  min-width: 0;
}