.logo-gallery {
 /*    .gallery {
        display: none;
    }

    @include from($tablet) {
        .gallery {
            display: block;
        }
        .marquee {
            display: none;
        }
    } */
    img {
      max-height: 120px
    }
}
