html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: $font-family-primary;
  color: $color-text;
  font-weight: $font-weight-regular;
  @include font-scale(16px, 18px);
}

html.no-smooth-scroll {
    scroll-behavior: auto;
}

.heading-1-jumbo,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  font-family: $font-family-heading;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.has-text-transform-none {
  text-transform: none;
}

.heading-1-jumbo {
  @include font-scale(50px, 100px);
  line-height: 1.1;
}

.heading-1 {
  @include font-scale(36px, 65px);
  line-height: 1.1;
}

.heading-2 {
  @include font-scale(28px, 48px);
  line-height: 1.2;
}

.heading-3 {
  @include font-scale(24px, 40px);
  line-height: 1.2;
}

.heading-4 {
  @include font-scale(20px, 32px);
  line-height: 1.2;
}

.heading-5 {
  @include font-scale(18px, 24px);
  line-height: 1.3;
}

.is-color-white {
  color: $color-text-light;

  a {
    color: $color-text-light;
  }

  .is-no-link {
    text-decoration: none;
    color: $color-text-light;

    &:hover {
      color: $color-text-light;
    }
  }
}

.is-headline-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.eyebrow {
  font-family: $font-family-heading;
  font-weight: $font-weight-regular;
  @include font-scale(16px, 20px);
  text-transform: uppercase;
}

.menu-link {
  font-family: $font-family-heading;
  font-weight: $font-weight-regular;
  font-size: 1rem;
  text-transform: uppercase;
}

.text {
  font-family: $font-family-primary;
  color: $color-text;
  font-weight: $font-weight-regular;
  @include font-scale(16px, 18px);

  &-super-jumbo {
    @include font-scale(28px, 48px);
    line-height: 1.1;
  }

  &-jumbo {
    @include font-scale(26px, 32px);
    line-height: 1.3
  }

  &-large {
    @include font-scale(20px, 24px);
  }

  &-small {
    @include font-scale(12px, 14px);
  }
}

.text-wysiwyg {
  overflow-wrap: break-word;

  ol {
    margin-top: 1rem;
    margin-left: 1.2rem;
  }

  &.has-check ul li {
    position: relative;
    padding-left: 2rem;
    margin-top: 1rem;

    &:before {
      content: '';
      background: url('../../images/check-dark.svg') center center/contain no-repeat;
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-block;
      height: 20px;
      width: 20px;
    }

  }

  &.is-secondary:before {
    background: url('../../images/check-green.svg') center center/contain no-repeat;
  }

  &.has-bullet ul li {
    list-style-type: disc;
    /* Standard Bulletpoint */
    position: static;
    margin-top: 0.5rem;
    margin-left: 1.2rem;
  }
}




.is-bold {
  font-family: $font-family-bold;
  font-weight: $font-weight-bold;
}

.is-semibold {
  font-family: $font-family-bold;
  font-weight: $font-weight-semibold;
}

.is-light {
  font-family: $font-family-bold;
  font-weight: $font-weight-light;
}

.is-uppercase {
  text-transform: uppercase;
}

.has-dark-lighter-gray-text {
  color: $color-text-dark-lighter-gray;
}

.is-regular {
  font-family: $font-family-bold;
  font-weight: $font-weight-regular;
}

.is-size-8 {
  font-size: 0.875rem !important;
}

.text-color-link {
    color: $color-text-link;
}

a {
  color: $color-text;
  text-decoration: underline;
  text-decoration-color: $button-color-bright;
  transition: color 0.25s ease-out;

  &:hover {
    color: $color-text-link;
    transition: color 0.25s ease-out;
    text-decoration: unset;
  }

  &.is-no-link {
    text-decoration: none;
    color: $color-text;

    &:hover {
      color: $color-text;
    }
  }
  &.is-color-white {
    color: $color-text-light;
  }
  &.hover-white:hover {
      color: $color-text-light;
  }
}