.location {
  .location-country {
    width: fit-content;

    &:hover {
      font-weight: $font-weight-semibold;
      text-decoration: underline;
      cursor: pointer;
    }

    &.is-active {
      font-weight: $font-weight-semibold;
      text-decoration: underline;
    }
  }

  .details-container,
  .location-image {
    display: none;

    &.is-active {
      display: block;
    }
  }
}