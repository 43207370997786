.anker-link {
    display: inline-block;
    font-family: $font-family-links;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    white-space: nowrap;
    border: 1px solid $button-color-primary;
    border-radius: 7px;
    background-color: transparent;
    padding: $anker-medium-y $anker-medium-x;
    transition: background-color 0.25s ease-out;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        background-color: $button-color-light-gray;
        color: $color-text;
        transition: background-color 0.25s ease-out;
    }
    &.is-small {
        padding: $anker-small-y $anker-small-x;
    }
    &.is-active {
        border: 1px solid $button-color-primary;
        color: $button-color-white;
        background-color: $button-color-primary;
    }

    &.is-white-outlined {
        color: white;
        border-color: white;

        &:hover {
            color: $color-text;
        }
    }
}
