.modal {
  z-index: 1000;

  .modal-content {
    max-width: min($fullhd, $container-max-width) - $container-offset;
  }

  .modal-visual {
    position: relative;
    background: transparent center center/cover no-repeat;
    align-self: stretch;

    > .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @include until($tablet) {
    &.is-fullwidth-mobile {
      .modal-content {
        padding: .5rem .5rem 1.5rem .5rem !important;
      }
    }
  }

  .modal-content {
    &.has-close-button {
      position: relative;

      .modal-close {
        position: absolute;
        z-index: 1;

        &:before,
        &:after {
          background-color: $color-background-light-gray;
        }
      }
    }
  }
}
