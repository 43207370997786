.spacer {
  width: 100%;

  &.editmode {
    background: #9cf5ff;
    margin: 3rem 0 4rem;
  }

  @for $i from 1 through 10 {
    &.spacer-rem-#{$i} {
      height: #{$i}rem;

      @include until($tablet) {
        height: #{calc($i/2)}rem;
      }
    }
  }

  &.is-same-mobile {
    @for $i from 1 through 10 {
      &.spacer-rem-#{$i} {
        height: #{$i}rem;
      }
    }
  }

  &.spacer-rem-0-5 {
    height: 0.5rem;

    @include until($tablet) {
      height: 0.25rem;
    }
  }
}

