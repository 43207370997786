.contact {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        @include from($tablet) {
            .buttons {
                flex-direction: row;
            }
        }
    }
}
