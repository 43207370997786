.scrollytelling-toggle-container {
    position: relative;

    .scrollytelling-toggle-content {

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100vh;
            transition: opacity 200ms ease-in-out;
        }

        .custom-component {
            > .container {
                flex-grow: 0;
            }
        }
    }

    .scrollytelling-toggle {
        position: sticky;
        top: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        path, circle {
            transition-duration: 500ms;
        }

        path, circle {
            transition-duration: 500ms;
        }

        #OFFcircle1,
        #OFFcircle2,
        #OFFcircle3 {
            opacity: 0;
            transform-origin: center center;
            animation: zoom-in-zoom-out 3s ease-in-out infinite;
        }

        #OFFcircle2 {
            animation-delay: 500ms;
        }

        #OFFcircle1 {
            animation-delay: 1s;
        }

        .circle {
            display: inline-block;
            position: absolute;
            top: calc(50% - 1rem);
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            background-color: #CFD4D4;
            z-index: -1;

            &:nth-of-type(1) {
                width: 45%;
                height: 45%;
                opacity: 0.5;
            }

            &:nth-of-type(2) {
                width: 73%;
                height: 73%;
                opacity: 0.4;
            }

            &:nth-of-type(3) {
                width: 100%;
                height: 100%;
                opacity: 0.4;
            }
        }
    }

    @include until($tablet) {
        .svg-container {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;

            .scrollytelling-toggle {
                height: 50vh;
                z-index: -1;
            }
        }

        .content-container {
            position: relative;
            padding-top: 50vh;
        }
    }
}

.editmode {
    .scrollytelling-toggle-container {
        position: relative;
        height: auto;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}