.list-items {
  ul li {
    position: relative;
    padding-left: 2.5rem;
    margin-bottom: 1.5rem;

    &:before {
      content: '';
      background: url('../../images/check-dark.svg') center center/contain no-repeat;
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-block;
      height: 20px;
      width: 24px;
    }

    &.is-secondary:before {
      background: url('../../images/check-green.svg') center center/contain no-repeat;
    }

  }

  .has-check {
    &:has(.heading-3)::before {
      top: 7px;
    }
    &:has(.text-large)::before {
      top: 6px;
    }

  }


}