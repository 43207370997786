.full-screen-header {
    position: relative;
    width: 100%;
    height: 100vh;
    justify-content: center;
    .content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $header-gap;
        justify-content: center;
        z-index: 3;
    }
}
