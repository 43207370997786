.box-wrapper:has(.is-shrink-to-fit) {
  display: inline-flex;
}

.box {
  padding: $box-padding;
  position: relative;

    @include from($tablet) {
        &.has-padding-large {
            padding: $box-padding-large;
        }
    }

  &.is-shrink-to-fit {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: $box-padding;
  }

  &.with-text-link {
    display: block;
  }

}