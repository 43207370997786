.background-img {
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cover-img {
    height: 100%;
    width: 100%;
    position: relative;
    img {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.scale-width {
    height: 100%;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}
.scale-height {
    height: 100%;
    width: 100%;    
    img {
        width: auto;
        height: 100%;
        object-fit: cover;
    }
    &.is-450 {
      height: 450px;
    }
}

.image {
  &-link {
    display: block;
  }

  &.has-round-corners {
    img{
      border-radius: 5px;
    } 
  }
  
  &.is-2by1 {
    img {
      aspect-ratio: 2 / 1;
    }
  }

  &.is-cover {
    img {
      height: 100%;
      object-fit: cover;
    }
    &.is-450 {
      height: 450px;
    }

  }

}