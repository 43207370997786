.customer-quote {

    .customer-name {
        display: flex;
        align-items: center;
        padding-right: 2rem;        
        .profile-image {
            flex-shrink: 0;
            width: 70px;
            height: 70px; 
            img {
                border-radius: 50%;
            }
        }
    }
    
    .customer-details {
      margin-top: 4rem !important;
    }

    .customer-logo {
      max-width: 200px;
    }
  
    @include from($tablet) {
        .has-seperator {
            display: flex;
            justify-content: flex-end;
            border-right: 1px solid rgba(255, 255, 255, 0.25);
        }
        .customer-logo {
            padding-left: 2rem;
        }
    }
}
