.bar {
    background-color: white;
    height: 100%;
    
    .head {
        padding: 2rem;
        img {
            position: absolute;
            top: 2rem;
            right: 1.5rem;
            width: 50px;
        }
        &.is-active {
            img {
                transform: rotate(0.5turn);
            }
        }
        .headline {
            width: 70%;
            display: flex;
            align-items: center;
            &::before {
                content: "";
                display: inline-block;
                margin-right: 0.5rem;
                width: 55px;
                height: 28px;
            }
        }
        .copy {
            width: 70%;
        }
    }
    .body {
        padding: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-height: $max-bar-height;
        overflow: hidden;
        transition: max-height 0.3s ease-out, padding-top 0.3s ease-out,
            padding-bottom 0.3s ease-out;
        &.is-collapsed {
            transition: max-height 0.3s ease-out, padding-top 0.3s ease-out,
                padding-bottom 0.3s ease-out;
            max-height: 0px;
            padding-top: 0rem;
            padding-bottom: 0rem;
            overflow: hidden;
        }
    }
    &.is-red {
        .head {
            border-bottom: 7px solid $object-color-red;
            .headline::before {
                background: url("../images/bean-red.svg") center center
                    no-repeat;
            }
        }
    }
    &.is-blue {
        .head {
            border-bottom: 7px solid $object-color-blue;
            .headline::before {
                background: url("../images/bean-blue.svg") center center
                    no-repeat;
            }
        }
    }
    &.is-yellow {
        .head {
            border-bottom: 7px solid $object-color-yellow;
            .headline::before {
                background: url("../images/bean-yellow.svg") center center
                    no-repeat;
            }
        }
    }
    &.is-orange {
        .head {
            border-bottom: 7px solid $object-color-orange;
            .headline::before {
                background: url("../images/bean-orange.svg") center center
                    no-repeat;
            }
        }
    }

    @include from($tablet) {
        .head {
            height: 17.5rem;
            @include from(840px) {
                height: 15rem;
            }
            img {
                display: none;
            }
            .headline {              
                width: 100%;
            }
            .copy {
                width: 100%;
            }
        }
        .body {
            &.is-collapsed {
                padding: 2rem;
                padding-top: 2rem;
                padding-bottom: 2rem;
                overflow: auto;
                max-height: $max-bar-height;
            }
        }
    }
}
