.card {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;

  img {
    scale: 1;
    transition: scale 0.25s ease-in-out;
    object-fit: cover;
    z-index: 1;
  }

  .description {
    a {
      text-decoration: unset;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.is-profile {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1.2;
    }

    .description {
      padding: 1.2rem 0 2rem 0;
    }
  }

  &.is-news {
    .description {
      padding: unset;
    }
  }

  &.is-case {
   aspect-ratio: 4/3;
 
    .description {
      transition: transform 0.5s ease-in-out;
      display: block;
      z-index: 4;
      padding: 1.2rem;
      background-color: $color-background-light-gray;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .copy {
        max-height: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .button-container {
    position: absolute;
    display: none;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .tag-container {
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
  }

  &.hover-animation {
    &:hover {
      img {
        transition: scale 0.8s ease-in-out;
        scale: 1.3;
      }

      .button-container {
        display: inline-block;
        z-index: 3;
      }

      .description {
        transition: transform 0.5s ease-in-out;
        transform: translateY(101%)
      }

      &::before {
        content: "";
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
        opacity: 0.5;
        filter: blur(1px);
      }
    }
  }

  @include from($tablet) {
    &.is-small {
      width: 75%;
    }

    &.is-medium {
      width: 80%;
    }

    &.is-large {
      width: 100%;
    }
  }
}

.big-teaser {
  .description {
    a {
      text-decoration: unset;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}